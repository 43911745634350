import { RoutePermissionsModel } from '@shared/model/route-permissions.model';
import { AppAccessEnum } from '@shared/enums/app-access.enum';

export const DELEGATION_ROUTE = {
  add: 'add',
  edit: 'edit',
} as const;
export const DELEGATION_PERMISSIONS: RoutePermissionsModel<
  typeof DELEGATION_ROUTE
> = {
  add: {
    code: AppAccessEnum.ac_Delegation_Managment,
  },
  edit: {
    code: AppAccessEnum.ac_Delegation_Managment,
  },
};
